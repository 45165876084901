import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from, of, merge, EMPTY } from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { SpinnerService } from 'src/app/services/ue/spinner.service';
import { ToasterService } from 'src/app/services/ue/toaster.service';
import { initializeApp } from 'firebase/app';
import { getAuth, getMultiFactorResolver, signInWithEmailAndPassword } from 'firebase/auth';
import * as CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { UEAPIService } from 'src/app/services/ue/api.service';
import { TimeOutDialogComponent } from 'src/app/shared/dialogs/time-out-dialog/time-out-dialog.component';
import { Router } from '@angular/router';

const resourceEndpoints = new Set([
  'userLogin', 'insightsById', 'kpiScoreBoardById', 'logEventsById',
  'networkTrafficById', 'downloadInitiator', 'uniqueSystemList', 'uniqueSystemListDestination',
  'classificationsList', 'uniqueDestinationList', 'userClassificationList', 'updateClassificationById', 'updateDestinationById',
  'profile', 'validate', 'getTimezoneDetails', 'logSizeById', 'anomaliesById', 'uniqueSystemTrafficList',
  'uniqueSystemDestinationTrafficList', 'organizations', 'discoverList', 'addDiscoverQuery', 'getDiscoverQuery',
  'updateDiscoverQuery', 'deleteDiscoverQuery', 'downloadReports', 'getEmailNotification', 'getEmailList',
  'getUserProfile', 'sendVerificationEmail', 'updateUserDetails', 'updateUniqueIp', 'getAnomaliesList', 'updateAnomaly',
  'updateNotification', 'updateEmailNotification', 'getExternalTrafficSummary', 'getEastWestTraffic',
]);

@Injectable()
export class UeApiInterceptor implements HttpInterceptor {

  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  private failedRequests: { request: HttpRequest<any> }[] = [];

  constructor(
    private spinner: SpinnerService,
    private toastr: ToasterService,
    private dialog: MatDialog,
    private apiService: UEAPIService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinner.show();
    const route: string[] = (request.url as string).split('/');
    const isResourceEndpoint = resourceEndpoints.has(route[route.length - 1]) || resourceEndpoints.has(route[route.length - 2]);

    if (isResourceEndpoint) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && err.error?.message === 'Token is Expired') {
          return this.handleTokenExpired(request, next);
        } else {
          this.handleHttpError(err);
          return throwError(() => err);
        }
      }),
      finalize(() => {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      })
    );
  }

  private handleTokenExpired(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          // Retry the original request with the new token
          return next.handle(this.addToken(request)).pipe(
            catchError((err) => {
              return throwError(() => err);
            })
          );
        })
      );
    } else {
      this.isRefreshing = true;

      return this.refreshAuthToken().pipe(
        switchMap((token: string) => {
          localStorage.setItem('idToken', token);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(true);

          // Retry all failed requests after refreshing the token
          return this.retryFailedRequests(next).pipe(
            // Retry the original request after all failed requests are retried
            switchMap(() => next.handle(this.addToken(request))),
            catchError((err) => {
              return throwError(() => err);
            })
          );
        }),
        catchError((err) => {
          if (err.code === 'auth/multi-factor-auth-required') {
            this.apiService.multiFactorResolver = getMultiFactorResolver(this.apiService.auth, err);
            const dialogRef = this.dialog.open(TimeOutDialogComponent, {
              width: '400px',
              height: '250px',
              disableClose: true,
              autoFocus: true,
            });

            return from(dialogRef.afterClosed()).pipe(
              switchMap((result) => {
                this.apiService.initializeClient();
                if (result) {
                  this.isRefreshing = false;
                  this.refreshTokenSubject.next(true);
                  return next.handle(this.addToken(request)).pipe(
                    catchError((error) => {
                      return throwError(() => error);
                    }),
                    finalize(() => {
                      this.spinner.hide();
                    })
                  );
                } else {
                  this.resetStorage();
                  return throwError(() => new Error('Multi-factor authentication required'));
                }
              })
            );
          } else if (err.code === 'auth/invalid-login-credentials') {
            this.handleInvalidCreds();
          }
          this.isRefreshing = false;
          this.handleHttpError(err);
          return throwError(() => err);
        })
      );
    }
  }

  private retryFailedRequests(next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.failedRequests.length === 0) {
      return EMPTY;
    }


    const retryObservables = this.failedRequests.map(({ request }) =>
      next.handle(this.addToken(request)).pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      )
    );

    this.failedRequests = [];

    return merge(...retryObservables);
  }

  private addToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const idToken = localStorage.getItem('idToken');
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${idToken}`
      }
    });
  }

  private refreshAuthToken(): Observable<string> {
    const app = initializeApp(this.apiService.firebaseConfig);
    const auth = getAuth(app);
    const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

    if (!userDetails.email || !userDetails.password) {
      return throwError(() => new Error('User details not found'));
    }

    const password: string = CryptoJS.AES.decrypt(userDetails.password, userDetails.email).toString(CryptoJS.enc.Utf8);

    return from(signInWithEmailAndPassword(auth, userDetails.email, password)).pipe(
      switchMap((res: any) => {
        if (res?._tokenResponse?.idToken) {
          localStorage.setItem('idToken', res._tokenResponse.idToken);
          return of(res._tokenResponse.idToken);
        } else {
          return throwError(() => new Error('Failed to refresh token'));
        }
      })
    );
  }

  private async handleInvalidCreds(): Promise<void> {
    await window.alert('Password has been changed, Please Sign In with New Password.');
    this.resetStorage();
  }

  private resetStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  private handleHttpError(err: HttpErrorResponse): void {
    switch (err.status) {
    case 404:
      this.toastr.showToastr('404 Not Found', 400);
      break;
    case 500:
      this.toastr.showToastr('Internal Server Error', 400);
      break;
    case 403:
      this.toastr.showToastr('Unauthorized Access', 400);
      break;
    default:
      break;
    }
  }
}
